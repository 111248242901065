<template>
  <div class="wrap">
    <el-dialog
      title="订单详情"
      :visible="isShow"
      @close="closeDialog1"
      :fullscreen="true"
    >
      <el-card class="box-card" shadow="never" style="margin-top: -20px">
        <dl
          style="
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
          "
          class="item1"
        >
          <div>
            <span class="boldText">订单号:</span>
            <span class="marginLeft">{{ orderInfo.No }}</span>
          </div>

          <div>
            <span class="boldText">下单时间:</span>
            <span class="marginLeft">{{ orderInfo.Time }}</span>
          </div>

          <div>
            <span class="boldText">交易状态:</span>
            <span class="marginLeft">{{
              computedStatus(orderInfo.tradeState)
            }}</span>
          </div>
        </dl>
      </el-card>

      <!-- 保单基本信息 -->
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="titleColor">保单基本信息</span>
        </div>

        <dl
          style="
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
          "
          class="item2"
        >
          <div class="width50">
            <span class="boldText">投保产品:</span>
            <span class="marginLeft">{{
              orderInfo.insuranceInfo.product
            }}</span>
          </div>
          <div class="width50">
            <span class="boldText">保险期间:</span>
            <span class="marginLeft">{{ orderInfo.insuranceInfo.time }}</span>
          </div>
          <div class="width50">
            <span class="boldText">投保单号:</span>
            <span class="marginLeft">{{ orderInfo.insuranceInfo.No }}</span>
          </div>

          <div class="width50">
            <span class="boldText">保单号:</span>
            <span class="marginLeft">{{
              orderInfo.insuranceInfo.NoFromIssueCompany
            }}</span>
          </div>

          <div class="width50">
            <span class="boldText">出单时间:</span>
            <span class="marginLeft">{{
              orderInfo.insuranceInfo.issueTime
            }}</span>
          </div>

          <div class="width50">
            <span class="boldText">保单类型:</span>
            <span class="marginLeft">{{ orderInfo.insuranceInfo.type }}</span>
          </div>

          <div class="width50">
            <span class="boldText">保单金额:</span>
            <span class="marginLeft">{{
              orderInfo.insuranceInfo.sum + " 元"
            }}</span>
          </div>

          <div class="width50">
            <span class="boldText">出单公司:</span>
            <span class="marginLeft">{{
              computedIssueCompany(orderInfo.insuranceInfo.issueCompany)
            }}</span>
          </div>
        </dl>
      </el-card>

      <!-- 投保人信息 -->
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="titleColor">投保人信息</span>
        </div>
        <dl
          style="
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
          "
          class="item2"
        >
          <div class="width50">
            <span class="boldText">投保企业名称:</span>
            <span class="marginLeft">{{ orderInfo.holder.holder_name }}</span>
          </div>

          <div class="width50">
            <span class="boldText">投保企业地址:</span>
            <span class="marginLeft">{{
              orderInfo.holder.holder_address
            }}</span>
          </div>

          <div class="width50">
            <span class="boldText">社会信用代码:</span>
            <span class="marginLeft">{{ orderInfo.holder.holder_idcard }}</span>
          </div>

          <div class="width50">
            <span class="boldText">社会工伤保险:</span>
            <span class="marginLeft">{{
              orderInfo.holder.is_social_security == 1 ? "是" : "否"
            }}</span>
          </div>

          <div class="width50">
            <span class="boldText">联系人电话:</span>
            <span class="marginLeft">{{ orderInfo.holder.linkPhoneNum }}</span>
          </div>
          <div class="width50">
            <span class="boldText">联系人名称:</span>
            <span class="marginLeft">{{ orderInfo.holder.linkName }}</span>
          </div>
          <div class="width50">
            <span class="boldText">联系人地址:</span>
            <span class="marginLeft">{{ orderInfo.holder.linkAddress }}</span>
          </div>

          <div class="width50">
            <span class="boldText">单位性质:</span>
            <span class="marginLeft">{{
              orderInfo.holder.business_nature_des
            }}</span>
          </div>
        </dl>
      </el-card>

      <!-- 被保人信息 -->
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="titleColor">被保人信息</span>
        </div>
        <dl
          style="
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
          "
          class="item2"
        >
          <div class="width50">
            <span class="boldText">被保企业名称:</span>
            <span class="marginLeft">{{ orderInfo.insurer.holder_name }}</span>
          </div>
          <div class="width50">
            <span class="boldText">被保企业地址:</span>
            <span class="marginLeft">{{
              orderInfo.insurer.holder_address
            }}</span>
          </div>
          <div class="width50">
            <span class="boldText">社会信用代码:</span>
            <span class="marginLeft">{{
              orderInfo.insurer.holder_idcard
            }}</span>
          </div>
          <div class="width50">
            <span class="boldText">行业领域:</span>
            <span class="marginLeft">{{
              orderInfo.insurer.business_scope
            }}</span>
          </div>
          <div class="width50">
            <span class="boldText">联系人电话:</span>
            <span class="marginLeft">{{ orderInfo.insurer.linkPhoneNum }}</span>
          </div>
          <div class="width50">
            <span class="boldText">联系人名称:</span>
            <span class="marginLeft">{{ orderInfo.insurer.linkName }}</span>
          </div>
          <div class="width50">
            <span class="boldText">联系人地址:</span>
            <span class="marginLeft">{{ orderInfo.insurer.linkAddress }}</span>
          </div>
          <div class="width50">
            <span class="boldText">单位性质:</span>
            <span class="marginLeft">{{
              orderInfo.insurer.business_nature_des
            }}</span>
          </div>
        </dl>
      </el-card>

      <!-- 保障责任 -->
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span class="titleColor">保障责任</span>
        </div>

        <dl class="item3">
          <div
            style="width: 50%; box-sizing: border-box; padding: 10px"
            v-for="(item, i) in reparationLimit"
            :key="i"
          >
            <div>
              <span class="boldText">险种名称:</span>
              <span style="padding-bottom: 20px">
                {{ " " + item.risk_name }}
              </span>
            </div>

            <dt style="padding: 10px 0">赔偿限额:</dt>
            <div
              style="
                padding: 15px 15px 15px 15px;
                background: #f0f9eb;
                width: 500px;
                color: #67c23a;
              "
            >
              <div v-for="(item, i) in item.duty_list[0].duty_limits" :key="i">
                {{ item.name + " : " + item.value / 10000 + " 万元" }}
              </div>
            </div>
          </div>
        </dl>
      </el-card>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["isShow", "orderId"],
  data() {
    return {
      reparationLimit: [], //保障责任

      orderInfo: {
        No: "D2108131125503367134", //订单号

        Time: "2021-08-13", //订单时间
        tradeState: "12", //交易状态

        insuranceInfo: {
          product: "广西安责险项目", // 投保产品：
          No: "T2108131125504538726", // 投保单号 自己生成的
          issueTime: "2021-08-18", //• 出单时间：/
          companyName: "111", // 被保企业名称
          field: "煤矿,井下开采", // 行业领域：
          sum: "922691.2", // 保单金额
          time: "2021-08-18 00时 至 2022-08-17 24时", // 保险期间：
          NoFromIssueCompany: "123", //保单号 投保公司生成的
          type: "电子保单", // 保单类型
          companyaddress: "广西壮族自治区 南宁市 兴宁区", // 被保企业地址
          creditCode: "1212e12e21", // 社会信用代码
          issueCompany: "中国人民财产保险股份有限公司广西壮族自治区分公司", // 出单公司
          name: "广西壮族自治区安全生产责任保险", //险种名称
          reparationLimit: [
            /*
            { title: "每次事故每人责任限额：", sum: "700000元" },
            { title: "企业累计责任限额: ", sum: "700000元" },
            { title: "企业每次事故责任限额: ", sum: "700000元" },
            { title: "每次事故及累计救援费用责任限额: ", sum: "700000元" },
            { title: "每次事故及累计法律费用责任限额: ", sum: "700000元" },
            { title: "每次事故财产损失责任限额: ", sum: "700000元" },
            { title: "每次事故每人医疗费用责任限额: ", sum: "700000元" },
            {
              title: "每次事故每位第三者随身携带财产损失责任限额: ",
              sum: "700000元",
            }, 
            */
          ],

          insurer: {
            //被保人信息
          },
          //保障责任
        },
        holder: {
          //投保人信息

          holder_name: "", //投保企业名称:广西壮族自治区烟花爆竹生产安全生产责任保险（人保）
          holder_address: "", //投保企业地址:2021-11-23 00:00:00 至 2022-02-22 23:59:59
          holder_idcard: "", //社会信用代码:T20211103105115104453016
          is_social_security: "", //社会工伤保险
          linkPhoneNum: "", //联系人电话:2021-11-03 10:51:14
          linkName: "", //联系人名称:电子
          linkAddress: "", //联系人地址:zhonghui
          business_nature_des: "", //单位性质:北京市-市辖区-东城区 as sas saas asa asas rrrrrrr
        },
        insurer: {
          holder_name: "", //被保企业名称:广西壮族自治区烟花爆竹生产安全生产责任保险（人保）
          holder_address: "", //被保企业地址:2021-11-23 00:00:00 至 2022-02-22 23:59:59
          holder_idcard: "", //社会信用代码:T20211103105115104453016
          business_scope: "", //行业领域:
          linkPhoneNum: "", //联系人电话:2021-11-03 10:51:14
          linkName: "", //联系人名称:电子
          linkAddress: "", ///联系人地址:zhonghui
          business_nature_des: "", //单位性质:北京市-市辖区-东城区 as sas saas asa asas rrrrrrr
        },
      },
    };
  },

  methods: {
    closeDialog1() {
      this.$emit("toParent", false);
    },
  },
  computed: {
    computedStatus() {
      return (status) => {
        if (status == 5) {
          return "已支付";
        } else if (status == 7) {
          return "出单失败";
        } else if (status == 8) {
          return "已取消";
        } else if (status == 9) {
          return "已退保";
        } else if (status == 10) {
          return "已出单";
        } else if (status == 11) {
          return "保障中";
        } else if (status == 12) {
          return "已失效";
        } else if (status == 13) {
          return "待支付";
        } else if (status == 14) {
          return "已逾期";
        } else if (status == 15) {
          return "待处理";
        }
      };
    },
    computedIssueCompany() {
      return (arr) => {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].party_role == 2) {
            return arr[i].name;
          }
        }
      };
    },
  },
  watch: {
    isShow: {
      handler(n, o) {
        if (n) {
          this.axios({
            url: "/api/v1/order/orderDetail",
            method: "post",
            data: {
              order_no: this.orderId,
            },
          }).then((res) => {
            var a = {
              proposal_no: "T20211103105115104453016", //proposal_no 投保单号
              cust_code: "2021UC93063565039707613", //cust_code 用户编号
              mail_address: "sdf@sdf.sdsdf.sd", //mail_address 邮寄地址
              mail_type: 1, //mail_type  电子邮箱还是邮寄地址
              policy_type: 1, //policy_type 保单类型：1电子2：纸质
              amount: 2000000000, //amount 总保额
              after_prem: "28436.13", //after_prem 计算后的保费
              before_prem: "28436.13", //before_prem 计算前的保费
              start_time: "2021-11-23 00:00:00", //start_time 保单开始时间
              end_time: "2022-02-22 23:59:59", //end_time    保单结束时间
              pay_time: "", //pay_time  支付时间
              order_no: "123", //order_no 订单号
              product_name:
                "广西壮族自治区烟花爆竹生产安全生产责任保险（人保）", //product_name  产品名称
              product_code: "PR2311", //product_code  产品编号
              surrender_reason: null, //surrender_reason 退保的原因。',
              create_time: "2021-11-03 10:51:15",
              status: "15",
              insurance_com_list: "1",
              policy_audit: 1,
              surrender_status: 0,
              extend: {
                //extend  附加
                applicant_startdate: "2021-11-03 10:51:14", //applicant_startdate 投保单时间
                insurance_year: 2021, //insurance_year 投保年份
                applicant_enddate: "", //applicant_enddate 投保单返回时间
                application_status: "", //application_status 出单状态
                renewal_falg: 1, //renewal_falg '0：新保 1：续保',
                renew_time: 0, //renew_time 续保时间
                renew_last_policy_no: "", //renew_last_policy_no  续保保单号
                electronic_policy: null, //electronic_policy 电子保单路径
                policy_no: null, //policy_no 保单号
                note: "",
                invoice_status: "0", //invoice_status 发票状态 0：未开具
                premiun_factor_vos: {
                  //premiun_factor_vos 保费明细
                  total_premium: "28436.1296",
                  single_premiums: {
                    premium_memo:
                      "(广西壮族自治区安全生产责任保险保费×企业风险系数×(1+每人责任限额调整因子（广西）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）)+(附加补充雇主责任险保费×企业风险系数×(1+每人责任限额调整因子（补充雇主）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）)+(附加燃放责任保险（适用于依法具有焰火燃放资质的企业）保费×企业风险系数×(1+每人责任限额调整因子（燃放责任保险）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）)+(附加补充公众责任险保费×企业风险系数×(1+每人责任限额调整因子（补充公众）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）)",
                    premium_formula:
                      "(26120*1.5*(1+0.3)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3)+(7030*1.5*(1+1)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3)+(3850*1.5*(1+1)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3)+(4510*1.5*(1+1)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3)",
                    risk_premiums: [
                      {
                        risk_code: "XZ9999",
                        risk_name: "广西壮族自治区安全生产责任保险",
                        risk_flag: "M",
                        risk_formulas:
                          "26120*1.5*(1+0.3)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3",
                        risk_memo:
                          "广西壮族自治区安全生产责任保险保费×企业风险系数×(1+每人责任限额调整因子（广西）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）",
                        risk_premiuml: "14915.6144",
                      },
                      {
                        risk_code: "XZ3167",
                        risk_name: "附加补充雇主责任险",
                        risk_flag: "A",
                        risk_formulas:
                          "7030*1.5*(1+1)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3",
                        risk_memo:
                          "附加补充雇主责任险保费×企业风险系数×(1+每人责任限额调整因子（补充雇主）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）",
                        risk_premiuml: "6176.0378",
                      },
                      {
                        risk_code: "XZ2992",
                        risk_name:
                          "附加燃放责任保险（适用于依法具有焰火燃放资质的企业）",
                        risk_flag: "A",
                        risk_formulas:
                          "3850*1.5*(1+1)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3",
                        risk_memo:
                          "附加燃放责任保险（适用于依法具有焰火燃放资质的企业）保费×企业风险系数×(1+每人责任限额调整因子（燃放责任保险）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）",
                        risk_premiuml: "3382.3251",
                      },
                      {
                        risk_code: "XZ8783",
                        risk_name: "附加补充公众责任险",
                        risk_flag: "A",
                        risk_formulas:
                          "4510*1.5*(1+1)*(1+(-0.1)+(-0.03))*(1+0.02)*(1+0.1)*0.3",
                        risk_memo:
                          "附加补充公众责任险保费×企业风险系数×(1+每人责任限额调整因子（补充公众）)×(1+安全文化建设示范企业因子+企业安全生产标准化因子)×(1+重大危险源调整因子)×(1+以往赔偿记录调整因子)×月数（生产、批发、零售经营者）",
                        risk_premiuml: "3962.1523",
                      },
                    ],
                    factor_resps: [
                      {
                        factor_code: "YZ7264",
                        factor_name: "每人责任限额调整因子（广西）",
                        factor_value: "XEZ7893",
                        factor_rate: "0.3",
                      },
                      {
                        factor_code: "YZ7333",
                        factor_name: "安全文化建设示范企业因子",
                        factor_value: "1",
                        factor_rate: "0.1",
                      },
                      {
                        factor_code: "YZ7413",
                        factor_name: "重大危险源调整因子",
                        factor_value: "368",
                        factor_rate: "0.02",
                      },
                      {
                        factor_code: "YZ7597",
                        factor_name: "以往赔偿记录调整因子",
                        factor_value: "375",
                        factor_rate: "0.1",
                      },
                      {
                        factor_code: "YZ7647",
                        factor_name: "企业安全生产标准化因子",
                        factor_value: "382",
                        factor_rate: "0.03",
                      },
                      {
                        factor_code: "YZ8213",
                        factor_name: "企业风险系数",
                        factor_value: "M130101",
                        factor_rate: "1.5",
                      },
                      {
                        factor_code: "YZ7723",
                        factor_name: "月数（生产、批发、零售经营者）",
                        factor_value: "3",
                        factor_rate: "0.3",
                      },
                      {
                        factor_code: "YZ0791",
                        factor_name: "每人责任限额调整因子（补充雇主）",
                        factor_value: "XEZ2692",
                        factor_rate: "1",
                      },
                      {
                        factor_code: "YZ8044",
                        factor_name: "每人责任限额调整因子（补充公众）",
                        factor_value: "XEZ0887",
                        factor_rate: "1",
                      },
                      {
                        factor_code: "YZ8145",
                        factor_name: "每人责任限额调整因子（燃放责任保险）",
                        factor_value: "XEZ5647",
                        factor_rate: "1",
                      },
                    ],
                  },
                },
                risk_list: [
                  //risk_list  保障责任限额 明细
                  {
                    risk_type_code: "0207060000",
                    risk_type_name: "生产责任保险",
                    risk_name: "广西壮族自治区安全生产责任保险（烟花爆竹行业）",
                    risk_code: "XZ9999",
                    risk_flag: "M",
                    reduce_prem: "0",
                    quantity: "1",
                    program_code: "FA8236",
                    program_name: "",
                    prem: "14915.6144",
                    before_prem: "14915.6144",
                    after_prem: "14915.6144",
                    actual_prem: "14915.6144",
                    amount: 5000000,
                    com_risk_value: "101384",
                    com_accident_limit: 700000,
                    com_linit_liability: 5000000,
                    duty_list: [
                      {
                        duty_desc: "",
                        duty_name: "从业人员及第三者人身伤害赔偿责任",
                        duty_code: "ZR0006",
                        duty_limits: [
                          {
                            code: "ZR0006",
                            name: "每次事故每人责任限额",
                            value: 700000,
                            value_code: "XEZ7893",
                          },
                          {
                            code: "ZR0006",
                            name: "累计责任限额",
                            value: 5000000,
                            value_code: "XEZ9410",
                          },
                          {
                            code: "ZR0006",
                            name: "每次事故责任限额",
                            value: 5000000,
                            value_code: "XEZ5587",
                          },
                          {
                            code: "ZR0006",
                            name: "每次事故及累计救援费用责任限额",
                            value: 1000000,
                            value_code: "XEZ4952",
                          },
                          {
                            code: "ZR0006",
                            name: "每次事故及累计法律费用责任限额",
                            value: 1000000,
                            value_code: "XEZ2352",
                          },
                          {
                            code: "ZR0006",
                            name: "每次事故每人医疗费用责任限额",
                            value: 1000000,
                            value_code: "XEZ3958",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    risk_type_code: "0207030000",
                    risk_type_name: "雇主责任保险",
                    risk_name: "附加补充雇主责任险",
                    risk_code: "XZ3167",
                    risk_flag: "A",
                    reduce_prem: "0",
                    quantity: "1",
                    program_code: "FA8115",
                    program_name: "",
                    prem: "6176.0378",
                    before_prem: "6176.0378",
                    after_prem: "6176.0378",
                    actual_prem: "6176.0378",
                    amount: "5000000",
                    com_risk_value: "101385",
                    com_accident_limit: 700000,
                    com_linit_liability: "5000000",
                    duty_list: [
                      {
                        duty_desc: "",
                        duty_name: "从业人员及第三者人身伤害赔偿责任",
                        duty_code: "ZR0010",
                        duty_limits: [
                          {
                            code: "ZR0010",
                            name: "每次事故每人医疗费用责任限额",
                            value: 0,
                            value_code: "XEZ7988",
                          },
                          {
                            code: "ZR0010",
                            name: "累计责任限额",
                            value: "5000000",
                            value_code: "XEZ7737",
                          },
                          {
                            code: "ZR0010",
                            name: "每次事故责任限额",
                            value: "5000000",
                            value_code: "XEZ8299",
                          },
                          {
                            code: "ZR0010",
                            name: "每次事故及累计救援费用责任限额",
                            value: 1000000,
                            value_code: "XEZ4124",
                          },
                          {
                            code: "ZR0010",
                            name: "每次事故及累计法律费用责任限额",
                            value: 1000000,
                            value_code: "XEZ6289",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    risk_type_code: "0207090000",
                    risk_type_name: "其他责任保险",
                    risk_name:
                      "附加燃放责任保险（适用于依法具有焰火燃放资质的企业）",
                    risk_code: "XZ2992",
                    risk_flag: "A",
                    reduce_prem: "0",
                    quantity: "1",
                    program_code: "FA4194",
                    program_name: "",
                    prem: "3382.3251",
                    before_prem: "3382.3251",
                    after_prem: "3382.3251",
                    actual_prem: "3382.3251",
                    amount: "5000000",
                    com_risk_value: "101387",
                    com_accident_limit: 700000,
                    com_linit_liability: "5000000",
                    duty_list: [
                      {
                        duty_desc: "",
                        duty_name: "从业人员及第三者人身伤害赔偿责任",
                        duty_code: "ZR0012",
                        duty_limits: [
                          {
                            code: "ZR0012",
                            name: "每次事故每人医疗费用责任限额",
                            value: 0,
                            value_code: "XEZ4033",
                          },
                          {
                            code: "ZR0012",
                            name: "累计责任限额",
                            value: "5000000",
                            value_code: "XEZ4283",
                          },
                          {
                            code: "ZR0012",
                            name: "每次事故责任限额",
                            value: "5000000",
                            value_code: "XEZ4689",
                          },
                          {
                            code: "ZR0012",
                            name: "每次事故及累计救援费用责任限额",
                            value: 1000000,
                            value_code: "XEZ5009",
                          },
                          {
                            code: "ZR0012",
                            name: "每次事故及累计法律费用责任限额",
                            value: 1000000,
                            value_code: "XEZ5504",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    risk_type_code: "0207010100",
                    risk_type_name: "场所责任保险",
                    risk_name: "附加补充公众责任险",
                    risk_code: "XZ8783",
                    risk_flag: "A",
                    reduce_prem: "0",
                    quantity: "1",
                    program_code: "FA3217",
                    program_name: "",
                    prem: "3962.1523",
                    before_prem: "3962.1523",
                    after_prem: "3962.1523",
                    actual_prem: "3962.1523",
                    amount: "5000000",
                    com_risk_value: "101386",
                    com_accident_limit: 700000,
                    com_linit_liability: "5000000",
                    duty_list: [
                      {
                        duty_desc: "",
                        duty_name: "从业人员及第三者人身伤害赔偿责任",
                        duty_code: "ZR0011",
                        duty_limits: [
                          {
                            code: "ZR0011",
                            name: "每次事故每人医疗费用责任限额",
                            value: 0,
                            value_code: "XEZ2090",
                          },
                          {
                            code: "ZR0011",
                            name: "累计责任限额",
                            value: "5000000",
                            value_code: "XEZ7576",
                          },
                          {
                            code: "ZR0011",
                            name: "每次事故责任限额",
                            value: "5000000",
                            value_code: "XEZ7583",
                          },
                          {
                            code: "ZR0011",
                            name: "每次事故及累计救援费用责任限额",
                            value: 1000000,
                            value_code: "XEZ4135",
                          },
                          {
                            code: "ZR0011",
                            name: "每次事故及累计法律费用责任限额",
                            value: 1000000,
                            value_code: "XEZ0775",
                          },
                        ],
                      },
                    ],
                  },
                ],
                insurance_com_list: [
                  //insurance_com_list   保险公司列表
                  {
                    company_code: "BX_CPIC",
                    name: "中国太平洋财产保险股份有限公司",
                    short_name: "中国太平洋财产保险股份有限公司",
                    logo_url: "2095223997771025138",
                    official_url: "http://property.cpic.com.cn",
                    branch_url:
                      "http://property.cpic.com.cn/xccbx/gygs/fzjg/index.shtml?skip",
                    party_role: "1",
                    share_type: "2",
                    share_scale: "0.22",
                    order: null,
                    insuring_type: "",
                    insuring_area_code: "",
                    insuring_area_name: "",
                  },
                  {
                    company_code: "BX_PICC",
                    name: "中国人民财产保险股份有限公司广西壮族自治区分公司",
                    short_name:
                      "中国人民财产保险股份有限公司广西壮族自治区分公司",
                    logo_url: "1702049445816045568",
                    official_url: "http://www.epicc.com.cn/",
                    branch_url: "http://www.epicc.com.cn/renbao/jigouwangdian/",
                    party_role: "1",
                    share_type: "1",
                    share_scale: "0.68",
                    order: null,
                    insuring_type: "",
                    insuring_area_code: "",
                    insuring_area_name: "",
                  },
                  {
                    company_code: "BX_CCIC",
                    name: "中国大地财产保险股份有限公司",
                    short_name: "中国大地财产保险股份有限公司",
                    logo_url: "2095223997771025141",
                    official_url: "http://www.ccic-net.com.cn/",
                    branch_url: "http://www.ccic-net.com.cn/zjdd/fzjg/",
                    party_role: "1",
                    share_type: "2",
                    share_scale: "0.1",
                    order: null,
                    insuring_type: "",
                    insuring_area_code: "",
                    insuring_area_name: "",
                  },
                  {
                    company_code: "BX_PICC",
                    name: "中国人民财产保险股份有限公司广西壮族自治区分公司",
                    short_name:
                      "中国人民财产保险股份有限公司广西壮族自治区分公司",
                    logo_url: "1702049445816045568",
                    official_url: "http://www.epicc.com.cn/",
                    branch_url: "http://www.epicc.com.cn/renbao/jigouwangdian/",
                    party_role: "2", //party_role 为2 的是出单的保险公司
                    share_type: "",
                    share_scale: "",
                    order: null,
                    insuring_type: "Y",
                    insuring_area_code:
                      "450100,451000,451200,451400,450500,450700,450600",
                    insuring_area_name: "null,null,null,null,null,null,null",
                  },
                  {
                    company_code: "BX_PICC",
                    name: "中国人民财产保险股份有限公司广西壮族自治区分公司",
                    short_name:
                      "中国人民财产保险股份有限公司广西壮族自治区分公司",
                    logo_url: "1702049445816045568",
                    official_url: "http://www.epicc.com.cn/",
                    branch_url: "http://www.epicc.com.cn/renbao/jigouwangdian/",
                    party_role: "3",
                    share_type: "",
                    share_scale: "",
                    order: null,
                    insuring_type: "",
                    insuring_area_code: "",
                    insuring_area_name: "",
                  },
                ],
              },
              holder: {
                //holder 投保人信息
                cust_code: "2021UC93063565039707613",
                holder_name: "zhonghui",
                holder_idcard: "sdfgasdfaafdsdssdg",
                holder_phone: "13335713700",
                holder_email: "",
                holder_card_type: "12",
                p_c_a_code: "110000,110100,110101",
                holder_pca_ch: "北京市-市辖区-东城区",
                holder_address: "as sas saas asa asas rrrrrrr",
                holder_extendInfos: {
                  industry: "M13,M1301,M130101",
                  industry_des: "烟花爆竹,生产,黑火药、引火线生产企业",
                  business_nature: "300,330",
                  business_nature_des: "企业单位,个体",
                  link_p_c_a_code: "110000,110100,110105",
                  link_p_c_a_name: "北京市,市辖区,东城区",
                  link_address: "as sas saas asa asas rrrrrrr",
                  is_group: "1",
                },
              },
              insurer: [
                //insurer被保人信息
                {
                  insurer_seq: 1,
                  is_holder: 1,
                  insurer_name: "zhonghui",
                  insurer_idcard: "sdfgasdfaafdsdssdg",
                  insurer_phone: "13335713700",
                  insurer_email: "",
                  relation_to_holder: 99,
                  insurer_card_type: "12",
                  p_c_a_code: "110000,110100,110101",
                  insurer_pca_ch: "北京市-市辖区-东城区",
                  insurer_address: "as sas saas asa asas rrrrrrr",
                  insurer_corporation: {
                    //insurer_corporation 被保人公司信息
                    name: "zhonghui",
                    industry: "M13,M1301,M130101",
                    industry_value: "烟花爆竹,生产,黑火药、引火线生产企业",
                    link_name: "xusongliang",
                    link_phone: "13335713700",
                    unit_nature: "300,330",
                    unit_nature_value: "企业单位,个体",
                    business_scope: "adsad adssad asd ads  adsdasdas asd",
                    legal_person: "",
                    email: "",
                    p_c_a_ch: "北京市-市辖区-东城区",
                    province: "110000",
                    city: "110100",
                    area: "110101",
                    address: "as sas saas asa asas rrrrrrr",
                    link_p_c_a_ch: "北京市-市辖区-东城区",
                    link_province: "110000",
                    link_city: "110100",
                    link_area: "110101",
                    link_address: "as sas saas asa asas rrrrrrr",
                    three_in_one_code: "sdfgasdfaafdsdssdg",
                    organization_ode: "",
                    corporation_code: "",
                    business_license_no: "sdfgasdfaafdsdssdg",
                    paperwork_info_list: [
                      {
                        code: "1634630412723057d2f9276f5e5557f80e9afbb1df",
                        name: "营业执照",
                        type: "bl",
                        file_url:
                          "/uploads/images/20211019/9d78aca504d2bc64b0b17e46e4b3d0c4.jpg",
                      },
                    ],
                  },
                  insurer_extendInfos: {
                    //insurer_extendInfos 被保人附加信息
                    is_social_security: "1", // is_social_security 是否参保社会工伤险
                    employed_num: 1, //employed_num 从业人员人数
                    is_downhole: "", //is_downhole是否井下开采
                  },
                },
              ],
              bank_info: null,
            };
            this.reparationLimit = [];
            var arr = res.data.result.extend.risk_list;
            for (var i = 0; i < arr.length; i++) {
              this.reparationLimit.push({
                risk_name: arr[i].risk_name,
                duty_list: arr[i].duty_list,
              });
            }

            this.orderInfo.No = res.data.result.order_no; //订单
            this.orderInfo.Time = res.data.result.create_time; //下单时间
            this.orderInfo.tradeState = res.data.result.status; //状态

            this.orderInfo.insuranceInfo = {
              product: res.data.result.product_name, //投保产品
              No: res.data.result.proposal_no, //投保单号
              issueTime: res.data.result.issue_time, //出单时间
              companyName: res.data.result.insurer[0].insurer_name,
              field:
                res.data.result.insurer[0].insurer_corporation.industry_value,
              sum: res.data.result.actual_prem, //保单金额
              time:
                res.data.result.start_time + " 至 " + res.data.result.end_time, //保险期间
              NoFromIssueCompany: res.data.result.extend.policy_no, //保单号
              type: res.data.result.policy_type == 1 ? "电子" : "纸质", //保单类型
              companyaddress:
                res.data.result.insurer[0].insurer_pca_ch +
                " " +
                res.data.result.insurer[0].insurer_address,

              issueCompany: res.data.result.extend.insurance_com_list,
              name: res.data.result.product_name,
            };

            this.orderInfo.holder = {
              holder_name: res.data.result.holder.holder_name, //投保企业名称:广西壮族自治区烟花爆竹生产安全生产责任保险（人保）
              holder_address:
                res.data.result.holder.holder_pca_ch +
                " " +
                res.data.result.holder.holder_address, //投保企业地址:2021-11-23 00:00:00 至 2022-02-22 23:59:59
              holder_idcard: res.data.result.holder.holder_idcard, //社会信用代码:T20211103105115104453016
              is_social_security:
                res.data.result.insurer[0].insurer_extendInfos
                  .is_social_security,
              linkPhoneNum: res.data.result.holder.holder_phone, //联系人电话:2021-11-03 10:51:14
              linkName: res.data.result.holder.holder_extendInfos.link_name, //联系人名称:电子
              linkAddress:
                res.data.result.holder.holder_extendInfos.link_p_c_a_name +
                " " +
                res.data.result.holder.holder_extendInfos.link_address, //联系人地址:zhonghui
              business_nature_des:
                res.data.result.holder.holder_extendInfos.business_nature_des, //单位性质:北京市-市辖区-东城区 as
            };

            this.orderInfo.insurer = {
              holder_name: res.data.result.insurer[0].insurer_corporation.name, //被保企业名称:广西壮族自治区烟花爆竹生产安全生产责任保险（人保）
              holder_address:
                res.data.result.insurer[0].insurer_corporation.p_c_a_ch +
                " " +
                res.data.result.insurer[0].insurer_corporation.address, //被保企业地址:2021-11-23 00:00:00 至 2022-02-22 23:59:59
              holder_idcard: res.data.result.insurer[0].insurer_idcard, //社会信用代码:T20211103105115104453016
              business_scope:
                res.data.result.insurer[0].insurer_corporation.industry_value, //行业领域:
              linkPhoneNum:
                res.data.result.insurer[0].insurer_corporation.link_phone, //联系人电话:2021-11-03 10:51:14
              linkName:
                res.data.result.insurer[0].insurer_corporation.link_name, //联系人名称:电子
              linkAddress:
                res.data.result.insurer[0].insurer_corporation.link_p_c_a_ch +
                " " +
                res.data.result.insurer[0].insurer_corporation.link_address, ///联系人地址:zhonghui
              business_nature_des:
                res.data.result.insurer[0].insurer_corporation
                  .unit_nature_value, //单位性质:北京市-市辖区-东城区 as sas saas asa asas rrrrrrr
            };

            /*          var arr1 =
              res.data.result.insurer[0].insurer_corporation
                .paperwork_info_list;
            for (var j = 0; j < arr1.length; j++) {
              if (arr1[j].type == "bl") {
                this.orderInfo.insuranceInfo.creditCode = arr1[j].code;
              }
            } */
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.wrap {
  text-align: left;
}
.box-card {
  margin-bottom: 20px;
}
.item1 dd {
  margin-left: 5px;
  margin-right: 30px;
}
.item2 {
  margin-bottom: 0;
}
.item2 dt {
  width: 100px;
}
.item2 dd {
  margin-left: 5px;
  width: 246px;
  margin-bottom: 10px;
  line-height: 20px;
}
.item3 {
  display: flex;
  flex-wrap: wrap;
  line-height: 20px;
}
.item3 dt {
  width: 70px;
}
.item3 dd {
  width: 580px;
  margin-left: 0;
}
.width50 {
  width: 50%;
  padding: 10px 20px;
  box-sizing: border-box;
}
.marginLeft {
  margin-left: 14px;
}
.boldText {
  font-weight: 600;
}
.titleColor {
  color: #67c23a;
}
</style>
